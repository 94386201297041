import {
  ReactNode,
  createContext,
  useCallback,
  useState,
  useMemo,
  useEffect,
} from "react";
import { useNavigate } from "react-router-dom";
import { login } from "../services/requests/auth";
import { toast } from "react-toastify";
import {
  addProduct,
  deleteProduct,
  editProduct,
  getProductById,
  getProducts,
} from "../services/requests/products";
import api from "../services/api";
import {
  adressByPostalCode,
  citiesByState,
  states,
} from "../services/requests/postalService";

import { getSpheres } from "../services/requests/spheres";
import {
  addDocument,
  deleteFile,
  getDocumentById,
  getDocuments,
  getProductImages,
  uploadProductImage,
  uploadAvatarImage,
  getFilesByFilter,
  sendDocuments,
} from "../services/requests/files";
import { X } from "lucide-react";

import { getCareer } from "../services/requests/career";
import { getFaq } from "../services/requests/faq";

import {
  FormDataTransaction,
  formatDataForApi,
} from "../pages/ProductsDetails/domain/Formatters";
import {
  getAllTransactions,
  getTransactionsByUserId,
  submitTransaction,
} from "../services/requests/transactions";

import { listBanks, profileAgent } from "../services/requests/profileAgent";
import { getCommissionsByUserId } from "../services/requests/commissions";
import { mainScreemDetails } from "../services/requests/main";
import { calculateShipping } from "../services/requests/shippingServices";
import {
  IBankAccount,
  IDocsResponse,
  IFilesResponse,
  IListBankResponse,
  ISpheresResponse,
  RespostaSimulacaoKangu,
} from "./interfaces";
import { IApiResponseTransactions } from "./interfaceTransactions";
import { getUsers } from "../services/requests/users";
import { ProductDetailsContentProps } from "../pages/ProductsDetails/domain/types";

interface BaseCrudProduct {
  name: string;
  description: string;
  price: number;
  auff: number;
  createUser: boolean;
  commissionDistributionSpheres?: number[];
  commissionDistributionGroup?: number[];
  commissionDistributionCarrer?: number[];
  commissionType: string;
  shippingValues?: {
    width: number;
    height: number;
    length: number;
    weight: number;
  };
  isCommissionable: boolean;
  directCommissionValue?: number;
  digitalProduct: boolean;
  presentialProduct?: boolean;
  freeShipping: boolean;
  recurrence: string;
  recorrentPayment?: boolean;
  isCademi?: boolean;
  cademiKey?: string;
  options?:
    | [{ title: string; description: string; choices: string[] }]
    | undefined;
}

export interface AddCrudProduct extends BaseCrudProduct {
  files: File[];
}

export interface EditCrudProduct extends BaseCrudProduct {
  id: string;
  newFiles: File[];
  removedFiles: IFile[];
}

export interface IFile {
  _id: string;
  name: string;
  originalName: string;
  fieldName: string;
  fieldId: string;
  path: string;
  fileUrl?: string;
  size: number;
  type: string;
  createdAt: string;
  description?: string;
}

interface Career {
  fronts: any[];
  _id: string;
  generatedAuffs: number;
  utilizedAuffs: number;
  careerLevel: string;
  careerNextLevel: string;
  careerNextLevelPoints: number;
  careerPoints: string;
  totalAuffs: number;
  personalAuffs: number;
  user: string;
}

interface Faq {
  _id: string;
  question: string;
  answer: string;
  position: number;
}
export interface User {
  expiresIn?: string;
  _id: string;
  active: boolean;
  userID?: string;
  name: string;
  cpf: string;
  email: string;
  role: string;
  password?: string | undefined;
  coupon?: string;
  graduation: string;
  commision: number;
  balance: number;
  phone: string;
  createdAt?: string;
  __v?: number;
  avatar?: string;
  referencia?: string;
  bairro?: string;
  birthday?: string;
  token?: string;
  address?: {
    street: string;
    number: string;
    complement: string;
    city: string;
    state: string;
    postalCode: string;
  };
  bankAccount?: IBankAccount;
  referedUser?: any;
  activeStatus?: string;
  nit?: string;
}

export interface IUserReport {
  address: {
    street: string;
    number: string;
    city: string;
    state: string;
    postalCode: string;
  };
  bankAccount: {
    pix: {
      type: string;
      key: string;
    }[];
    name: string;
    bank: string;
    number: number;
    ispb: string;
    cpf: string;
    ag: number;
    cc: number;
    dv: string;
  };
  _id: string;
  name: string;
  cpf: string;
  email: string;
  role: string;
  active: boolean;
  activeStatus: string;
  graduation: string;
  phone: string;
  balance: number;
  commission: number;
  createdAt: string;
  __v: number;
  birthday: string;
  avatar: string;
  lastAccess: Date;
}

interface IContextApi {
  isAuthenticated: boolean;
  loginRequest: (email: string, password: string) => void;
  logoutRequest: () => void;
  user?: User | null;
  profileEditAgent: (id: string, data: User) => void;
  editAgentProfile: boolean;
  setEditAgentProfile: (
    action: boolean | ((action: boolean) => boolean)
  ) => void;
  dimensions: {
    width: number;
    height: number;
  };
  drawerOpen: boolean;
  setDrawerOpen: React.Dispatch<React.SetStateAction<boolean>>;
  getAllDocuments: (type?: string) => void;
  getAllUsers: () => void;
  documentById: (documentId: string) => void;
  clearDocumentFiltered: () => void;
  documentFiltered: IFile;
  deleteDocument: (originalName: string) => void;
  documents: IDocsResponse[];
  getAllTransactionsByUserId: (userId: string) => void;
  transactions: any[];
  getAllCommissionsByUserId: (userId: string) => void;
  mainScreemDetails: (id: string) => void;
  commissions: any;
  getAllProducts: () => void;
  getAllProductImages: (id: string) => void;
  clearProductFiltered: () => void;
  addProductRequest: (product: AddCrudProduct) => void;
  addDocumentRequest: (
    name: string,
    description: string,
    file: File,
    uploadedBy: string
  ) => void;
  editProductRequest: (product: EditCrudProduct) => void;
  deleteProductRequest: (id: string) => void;
  getAdressByPostalCode: (postalCode: string) => void;
  getAllStates: (idUf?: string) => void;
  getCitiesByUf: (ufId: string) => void;
  getSpheresByUser: (userId: string) => void;
  getAllCareer: () => void;
  getAllFaq: () => void;
  getShippingCost: (payload: any) => void;
  getBanks: () => void;
  shippingCostResponse: RespostaSimulacaoKangu[];
  career?: Career;
  users: IUserReport[];
  allFaq: Faq[];
  ufs: [
    {
      id: number;
      sigla: string;
      nome: string;
      regiao: {
        id: number;
        sigla: string;
        nome: string;
      };
    }
  ];
  products: [
    {
      _id: string;
      name: string;
      active: boolean;
      description: string;
      price: number;
      auff: number;
      imageUrls: string[];
      isCommissionable: boolean;
      directCommissionValue?: number;
      commissionType: string;
      createUser: boolean;
      commissionDistributionSpheres?: number[];
      commissionDistributionGroup?: number[];
      commissionDistributionCarrer: number[];
      shippingValues?: {
        width: number;
        height: number;
        length: number;
        weight: number;
      };
      digitalProduct: boolean;
      freeShipping: boolean;
      recurrence: string;
    }
  ];
  productsById: (id: string) => void;
  productFiltered: {
    _id: string;
    name: string;
    description: string;
    price: number;
    auff: number;
    imageUrls: string[];
    isCommissionable: boolean;
    directCommissionValue?: number;
    commissionType: string;
    createUser: boolean;
    commissionDistributionSpheres?: number[];
    commissionDistributionGroup?: number[];
    commissionDistributionCarrer: number[];
    shippingValues?: {
      width: number;
      height: number;
      length: number;
      weight: number;
    };
    digitalProduct: boolean;
    freeShipping: boolean;
    recurrence: string;
    isCademi?: boolean;
    cademiKey?: string;
  };
  productImages: IFile[];
  adress: {
    cep: string;
    logradouro: string;
    complemento: string;
    bairro: string;
    localidade: string;
    uf: string;
    ibge: string;
    gia: string;
    ddd: string;
    siafi: string;
  };
  cities: [
    {
      id: number;
      nome: string;
      microrregiao: {
        id: number;
        nome: string;
        mesorregiao: {
          id: number;
          nome: string;
          UF: {
            id: number;
            sigla: string;
            nome: string;
            regiao: {
              id: number;
              sigla: string;
              nome: string;
            };
          };
        };
      };
    }
  ];
  spheresResp: ISpheresResponse;
  startTransaction: (
    formData: FormDataTransaction,
    startTransaction: ProductDetailsContentProps["saleIdentification"]
  ) => Promise<void>;
  editAvatar: (id: string, file: File) => void;
  getFiles: (fieldName: string, id: string) => void;
  files: IFilesResponse[];
  removeFile: (originalName: string) => void;
  sendDocumentsRequest: (
    type: string,
    name: string,
    description: string,
    file: File,
    uploadedBy: string
  ) => void;
  banks: IListBankResponse[];
  listAllTransactions: () => void;
  allTransactions: IApiResponseTransactions[];
}

export const ContextApi = createContext<IContextApi>({
  isAuthenticated: false,
  loginRequest: (email: string, password: string) => {},
  logoutRequest: () => {},
  dimensions: {
    width: 0,
    height: 0,
  },
  user: undefined,
  users: [],
  getAllUsers: () => {},
  getAllTransactionsByUserId: (userId: string) => {},
  transactions: [],
  getAllCommissionsByUserId: (userId: string) => {},
  addDocumentRequest: (
    name: string,
    description: string,
    file: File,
    uploadedBy: string
  ) => {},
  commissions: undefined,
  profileEditAgent: (id: string, data: User) => {},
  editAgentProfile: false,
  setEditAgentProfile: (action: boolean | ((action: boolean) => boolean)) => {},
  getAllProducts: () => {},
  getAllDocuments: (type?: string) => {},
  deleteDocument: (originalName: string) => {},
  documentById: (documentId: string) => {},
  documentFiltered: {
    _id: "",
    createdAt: "",
    fieldId: "",
    fieldName: "",
    name: "",
    originalName: "",
    path: "",
    size: 0,
    type: "",
  },
  clearDocumentFiltered: () => {},
  documents: [],
  getAllProductImages: (id: string) => {},
  productImages: [],
  addProductRequest: () => {},
  editProductRequest: () => {},
  clearProductFiltered: () => {},
  deleteProductRequest: () => {},
  drawerOpen: false,
  setDrawerOpen: () => {},
  getAdressByPostalCode: (postalCode: string) => {},
  mainScreemDetails: (id: string) => {},
  getAllStates: (idUf?: string) => {},
  getCitiesByUf: (ufId: string) => {},
  getSpheresByUser: (userId: string) => {},
  getAllCareer: () => {},
  getAllFaq: () => {},
  getShippingCost: (payload: any) => {},
  getBanks: () => {},
  career: undefined,
  allFaq: [] as Faq[],
  ufs: [
    {
      id: 0,
      sigla: "",
      nome: "",
      regiao: {
        id: 0,
        sigla: "",
        nome: "",
      },
    },
  ],
  products: [
    {
      _id: "",
      name: "",
      description: "",
      active: false,
      price: 0,
      auff: 0,
      imageUrls: [""],
      isCommissionable: false,
      directCommissionValue: 0,
      commissionType: "",
      createUser: false,
      commissionDistributionSpheres: [],
      commissionDistributionGroup: [],
      commissionDistributionCarrer: [],
      shippingValues: undefined,
      digitalProduct: false,
      freeShipping: false,
      recurrence: "",
    },
  ],
  productsById: () => {},
  productFiltered: {
    _id: "",
    name: "",
    description: "",
    price: 0,
    auff: 0,
    imageUrls: [""],
    isCommissionable: false,
    directCommissionValue: 0,
    commissionType: "",
    createUser: false,
    commissionDistributionSpheres: [],
    commissionDistributionGroup: [],
    commissionDistributionCarrer: [],
    shippingValues: undefined,
    digitalProduct: false,
    freeShipping: false,
    recurrence: "",
    isCademi: false,
    cademiKey: "",
  },
  adress: {
    cep: "",
    logradouro: "",
    complemento: "",
    bairro: "",
    localidade: "",
    uf: "",
    ibge: "",
    gia: "",
    ddd: "",
    siafi: "",
  },
  cities: [
    {
      id: 0,
      nome: "",
      microrregiao: {
        id: 0,
        nome: "",
        mesorregiao: {
          id: 0,
          nome: "",
          UF: {
            id: 0,
            sigla: "",
            nome: "",
            regiao: {
              id: 0,
              sigla: "",
              nome: "",
            },
          },
        },
      },
    },
  ],
  spheresResp: {
    rootNode: {
      active: false,
      address: {
        city: "",
        number: "",
        state: "",
        street: "",
        postalCode: "",
      },
      avatar: "",
      children: [],
      email: "",
      name: "",
      phone: "",
      role: "",
      salesByProduct: {},
      userId: "",
    },
    totalSellsByProduct: {},
  },
  startTransaction: async (
    formData: FormDataTransaction,
    startTransaction: ProductDetailsContentProps["saleIdentification"]
  ) => {},
  shippingCostResponse: [],
  editAvatar: (id: string, file: File) => {},
  getFiles: (fieldName: string, id: string) => {},
  files: [],
  removeFile: (originalName: string) => {},
  sendDocumentsRequest: (
    type: string,
    name: string,
    description: string,
    file: File,
    uploadedBy: string
  ) => {},
  banks: [],
  listAllTransactions: () => {},
  allTransactions: [],
});

interface Props {
  children: ReactNode;
}

const ContextProvider: React.FC<Props> = ({ children }) => {
  const storedUser = localStorage.getItem("user");
  const navigate = useNavigate();

  const [user, setUser] = useState<User | undefined>(
    storedUser ? JSON.parse(storedUser) : undefined
  );
  const [products, setProducts] = useState<any>([]);
  const [transactions, setTransactions] = useState<any[]>([]);
  const [allTransactions, setAllTransactions] = useState<any[]>([]);
  const [commissions, setCommissions] = useState<any[]>();
  const [productImages, setProductImages] = useState<IFile[]>([]);
  const [productFiltered, setProductFiltered] = useState<any>();
  const [documentFiltered, setDocumentFiltered] = useState<any>();
  const [adress, setAdress] = useState<any>();
  const [ufs, setUfs] = useState<any>([]);
  const [cities, setCities] = useState<any>([]);
  const [users, setUsers] = useState<any>([]);
  const [drawerOpen, setDrawerOpen] = useState<boolean>(false);
  const [spheresResp, setSpheresResp] = useState<any>([]);
  const [career, setCareer] = useState<Career>();
  const [documents, setDocuments] = useState<IDocsResponse[]>([]);
  const [dimensions, setDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  const [allFaq, setAllFaq] = useState<Faq[]>([]);
  const [editAgentProfile, setEditAgentProfile] = useState(false);
  const [shippingCostResponse, setShippingCostResponse] = useState<
    RespostaSimulacaoKangu[]
  >([]);
  const [files, setFiles] = useState<IFilesResponse[]>([]);
  const [banks, setBanks] = useState<IListBankResponse[]>([]);

  const isAuthenticated = useMemo(() => {
    return !!user;
  }, [user]);

  const handleResize = () => {
    setDimensions({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize, false);
  }, []);

  const logoutRequest = useCallback(() => {
    setUser(undefined);
    localStorage.clear();
    api.defaults.headers.Authorization = "";
  }, []);

  const loginRequest = useCallback(
    (email: string, password: string) => {
      const request = login(email, password);
      toast.promise(request, {
        pending: {
          render() {
            return "Carregando...";
          },
        },
        success: {
          render({ data }: any) {
            const token = data.data.token;
            api.defaults.headers.Authorization = `Bearer ${token}`;
            const expiresIn = data.data.expiresIn;
            const user = { ...data.data.user, token, expiresIn };
            setUser(user);
            if (user.role !== "MASTER") {
              navigate("/");
              localStorage.setItem("user", JSON.stringify(user));
              return "Logado com sucesso!";
            }

            navigate("/master/");
            localStorage.setItem("user", JSON.stringify(user));
            return "Logado com sucesso!";
          },
        },
        error: {
          render({ data }: any) {
            const message = data.response.data.message;
            return message;
          },
        },
      });
    },
    [navigate]
  );

  const getAllTransactionsByUserId = useCallback((userId: string) => {
    const request = getTransactionsByUserId(userId);
    request
      .then((response: any) => {
        const data = response?.data;
        setTransactions(data);
      })
      .catch((error) => {
        toast.error("Falha ao carregar transações!");
      });
  }, []);

  const listAllTransactions = useCallback(() => {
    const request = getAllTransactions();

    request
      .then((response: any) => {
        const data = response?.data;
        setAllTransactions(data);
      })
      .catch((error) => {
        toast.error("Falha ao carregar transações!");
      });
  }, []);

  const getAllUsers = useCallback(() => {
    const request = getUsers();
    toast.promise(request, {
      pending: {
        render() {
          return "Carregando...";
        },
      },
      success: {
        render({ data }: any) {
          setUsers(data?.data);
          return "Usuários carregados com sucesso!";
        },
      },
      error: {
        render({ data }: any) {
          //TODO
          return "Falha ao carregar usuários!";
        },
      },
    });
  }, []);

  const getAllCommissionsByUserId = useCallback((userId: string) => {
    const request = getCommissionsByUserId(userId);
    request
      .then((response: any) => {
        const data = response?.data;
        setCommissions(data);
      })
      .catch((error) => {
        toast.error("Falha ao carregar comissões!");
      });
  }, []);

  const startTransaction = useCallback(
    async (
      formData: FormDataTransaction,
      saleIdentification: ProductDetailsContentProps["saleIdentification"]
    ) => {
      try {
        const payload = formatDataForApi(formData, productFiltered, adress);
        if (!payload) {
          toast.error(
            "Esta faltando uma informacao, por favor check o formulario e tente novamente"
          );
          return;
        }

        const { checkouts } = await submitTransaction(
          payload,
          saleIdentification
        );
        window.location.href = checkouts[0].payment_url;
      } catch (error: any) {
        toast.error("Erro ao procesar a compra", error);
      }
    },
    [productFiltered, adress]
  );

  const getAllProductImages = useCallback((id: string) => {
    getProductImages(id)
      .then((data: any) => {
        setProductImages(data?.data?.response);
      })
      .catch(() => {
        toast.error("Falha ao carregar imagens!");
      });
  }, []);

  const getAllProducts = useCallback(() => {
    const request = getProducts();
    request
      .then((response: any) => {
        const data = response?.data;
        setProducts(data?.products);
      })
      .catch((error) => {
        toast.error("Falha ao carregar produtos!");
      });
  }, []);

  const getAllDocuments = useCallback((type?: string) => {
    const request = getDocuments(type);
    request
      .then((response: any) => {
        const data = response?.data;
        setDocuments(data);
      })
      .catch((error) => {
        toast.error("Falha ao carregar documentos!");
      });
  }, []);

  const clearDocumentFiltered = useCallback(() => {
    setDocumentFiltered(null);
  }, []);

  const clearProductFiltered = useCallback(() => {
    setProductFiltered(null);
    setProductImages([]);
  }, []);

  const productsById = useCallback((id: string) => {
    const request = getProductById(id);
    request
      .then((response: any) => {
        const data = response?.data;
        const paylod = {
          ...data?.product,
          imageUrls: [...data?.imageUrls],
        };
        setProductFiltered(paylod);
      })
      .catch((error) => {
        toast.error("Falha ao carregar produtos!");
      });
  }, []);

  const documentById = useCallback((documentId: string) => {
    const request = getDocumentById(documentId);

    request
      .then((response: any) => {
        const data = response.data;
        setDocumentFiltered(data?.data);
      })
      .catch((error) => {
        toast.error("Falha ao carregar documento!");
      });
  }, []);

  const addProductRequest = useCallback(
    async (product: AddCrudProduct) => {
      const toastId = toast.loading("Carregando...");
      addProduct(product)
        .then((data: any) => {
          const id = data.data._id;

          uploadProductImage(id, product.files)
            .then(() => {
              toast.update(toastId, {
                render: "Produto cadastrado com sucesso!",
                type: "success",
                isLoading: false,
                autoClose: 5000,
                closeButton: <X size={16} color="#8B8B8B" />,
                closeOnClick: true,
              });
              navigate("/admin/products");
            })
            .catch(() => {
              deleteProduct(id).finally(() => {
                toast.update(toastId, {
                  render: "Falha ao cadastrar o produto!",
                  type: "error",
                  isLoading: false,
                  autoClose: 5000,
                  closeButton: <X size={16} color="#8B8B8B" />,
                  closeOnClick: true,
                });
              });
            });
        })
        .catch(() => {
          toast.update(toastId, {
            render: "Falha ao cadastrar produto!",
            type: "error",
            isLoading: false,
            autoClose: 5000,
            closeButton: <X size={16} color="#8B8B8B" />,
            closeOnClick: true,
          });
        });
    },
    [navigate]
  );

  const editProductRequest = useCallback(
    async (product: EditCrudProduct) => {
      const toastId = toast.loading("Carregando...");

      product.removedFiles.forEach(async (file) => {
        deleteFile(file.originalName);
      });

      if (product.newFiles.length > 0) {
        uploadProductImage(product.id, product.newFiles).then(() => {
          console.log(product);

          editProduct(product)
            .then(() => {
              toast.update(toastId, {
                render: "Produto editado com sucesso!",
                type: "success",
                isLoading: false,
                autoClose: 2000,
                closeButton: <X size={16} color="#8B8B8B" />,
                closeOnClick: true,
              });
              navigate("/admin/products");
            })
            .catch(() => {
              toast.update(toastId, {
                render: "Falha ao editar produto!",
                type: "error",
                isLoading: false,
                autoClose: 2000,
                closeButton: <X size={16} color="#8B8B8B" />,
                closeOnClick: true,
              });
            });
        });
      } else {
        editProduct(product)
          .then(() => {
            toast.update(toastId, {
              render: "Produto editado com sucesso!",
              type: "success",
              isLoading: false,
              autoClose: 2000,
              closeButton: <X size={16} color="#8B8B8B" />,
              closeOnClick: true,
            });
            navigate("/admin/products");
          })
          .catch(() => {
            toast.update(toastId, {
              render: "Falha ao editar produto!",
              type: "error",
              isLoading: false,
              autoClose: 2000,
              closeButton: <X size={16} color="#8B8B8B" />,
              closeOnClick: true,
            });
          });
      }
    },
    [navigate]
  );

  const deleteProductRequest = useCallback(
    (id: string) => {
      const request = deleteProduct(id);
      toast.promise(request, {
        pending: {
          render() {
            return "Carregando...";
          },
        },
        success: {
          render({ data }: any) {
            setProducts(products.filter((product: any) => product._id !== id));
            return "Produto removido com sucesso!";
          },
        },
        error: {
          render({ data }: any) {
            return "Falha ao remover produto!";
          },
        },
      });
    },
    [products]
  );

  const addDocumentRequest = useCallback(
    (name: string, description: string, file: File, uploadedBy: string) => {
      const request = addDocument(name, description, file, uploadedBy);
      toast.promise(request, {
        pending: {
          render() {
            return "Carregando...";
          },
        },
        success: {
          render({ data }: any) {
            navigate("/admin/documents");
            return "Documento adicionado com sucesso!";
          },
        },
        error: {
          render({ data }: any) {
            return "Falha ao adicionar documento!";
          },
        },
      });
    },
    [navigate]
  );

  const sendDocumentsRequest = useCallback(
    (
      type: string,
      name: string,
      description: string,
      file: File,
      uploadedBy: string
    ) => {
      const request = sendDocuments(type, name, description, file, uploadedBy);
      toast.promise(request, {
        pending: {
          render() {
            return "Carregando...";
          },
        },
        success: {
          render({ data }: any) {
            return "Documento adicionado com sucesso!";
          },
        },
        error: {
          render({ data }: any) {
            return "Falha ao adicionar documento!";
          },
        },
      });
    },
    [navigate]
  );

  const deleteDocument = useCallback(
    (originalName: string) => {
      const request = deleteFile(originalName);
      toast.promise(request, {
        pending: {
          render() {
            return "Carregando...";
          },
        },
        success: {
          render({ data }: any) {
            setDocuments(
              documents.filter((doc: any) => doc.key !== originalName)
            );
            return "Documento removido com sucesso!";
          },
        },
        error: {
          render({ data }: any) {
            return "Falha ao remover documento!";
          },
        },
      });
    },
    [documents]
  );

  const getAdressByPostalCode = useCallback((postalCode: string) => {
    const request = adressByPostalCode(postalCode);
    toast.promise(request, {
      pending: {
        render() {
          return "Carregando...";
        },
      },
      success: {
        render({ data }: any) {
          //TODO
          setAdress(data?.data);
          let idUf = data?.data?.ibge.slice(0, 2);
          getAllStates(idUf);
          return "Endereço carregado com sucesso!";
        },
      },
      error: {
        render({ data }: any) {
          //TODO
          setAdress(null);
          return "Falha ao carregar endereço!";
        },
      },
    });
  }, []);

  const getAllStates = useCallback((idUf?: string) => {
    const request = states(idUf);
    toast.promise(request, {
      pending: {
        render() {
          return "Carregando...";
        },
      },
      success: {
        render({ data }: any) {
          if (data?.data?.id) {
            setUfs(new Array(data?.data));
            getCitiesByUf(data?.data?.id.toString());
          } else {
            setUfs(data?.data);
          }
          return "";
        },
        style: {
          display: "none",
        },
      },
      error: {
        render({ data }: any) {
          //TODO
          return "Falha ao carregar estados!";
        },
      },
    });
  }, []);

  const getCitiesByUf = useCallback((ufId: string) => {
    const request = citiesByState(ufId);
    request
      .then((response: any) => {
        const data = response?.data;
        setCities(data);
      })
      .catch((error) => {
        toast.error("Falha ao carregar cidades!");
      });
  }, []);

  const getAllCareer = useCallback(() => {
    const request = getCareer();
    toast.promise(request, {
      pending: {
        render() {
          return "Carregando...";
        },
      },
      success: {
        render({ data }: any) {
          //TODO
          setCareer(data?.data);
          return "Carreira carregada com sucesso!";
        },
      },
      error: {
        render({ data }: any) {
          //TODO
          return "Falha ao carregar carreira!";
        },
      },
    });
  }, []);

  const getSpheresByUser = useCallback((userId: string | undefined) => {
    const request = getSpheres(userId);

    request
      .then((response: any) => {
        const data = response?.data;
        setSpheresResp(data);
      })
      .catch((error) => {
        toast.error("Falha ao carregar Esferas!");
      });
  }, []);

  const getAllFaq = useCallback(() => {
    const request = getFaq();
    toast.promise(request, {
      pending: {
        render() {
          return "Carregando...";
        },
      },
      success: {
        render({ data }: any) {
          //TODO
          setAllFaq(data?.data);
          return "";
        },

        style: {
          display: "none",
        },
      },
      error: {
        render({ data }: any) {
          //TODO
          return "Falha ao carregar perguntas!";
        },
      },
    });
  }, []);

  const profileEditAgent = useCallback((id: string, userData: User) => {
    console.log(userData);
    const request = profileAgent(id, userData);
    toast.promise(request, {
      pending: {
        render() {
          setEditAgentProfile(true);
          return "Carregando...";
        },
      },
      success: {
        render({ data }: any) {
          const updatedUser = { ...user, ...data.data };
          setUser(updatedUser);
          localStorage.setItem("user", JSON.stringify(updatedUser));
          setEditAgentProfile(false);
          return "Perfil atualizado com  com sucesso!";
        },
      },
      error: {
        render({ data }: any) {
          setEditAgentProfile(true);
          return "Falha ao atualizar o perfil!";
        },
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getShippingCost = useCallback((payload: any) => {
    const request = calculateShipping(payload);
    toast.promise(request, {
      pending: {
        render() {
          return "Carregando...";
        },
      },
      success: {
        render({ data }: any) {
          setShippingCostResponse(data?.data);
          return "Frete calculado com sucesso!";
        },
      },
      error: {
        render({ data }: any) {
          return "Falha ao calcular frete!";
        },
      },
    });
  }, []);

  const editAvatar = useCallback((id: string, file: File) => {
    const request = uploadAvatarImage(id, file);
    toast.promise(request, {
      pending: {
        render() {
          return "Carregando...";
        },
      },
      success: {
        render() {
          return "Avatar atualizado com sucesso!";
        },
      },
      error: {
        render({ data }: any) {
          return "Falha ao atualizar avatar!";
        },
      },
    });
  }, []);

  const getFiles = useCallback((fieldName: string, id: string) => {
    const request = getFilesByFilter(fieldName, id);
    toast.promise(request, {
      pending: {
        render() {
          return "Carregando...";
        },
      },
      success: {
        render({ data }: any) {
          setFiles(data?.data?.response);
          return "Documentos carregados com sucesso!";
        },
      },
      error: {
        render({ data }: any) {
          return "Falha ao carregar documentos!";
        },
        style: {
          display: "none",
        },
      },
    });
  }, []);

  const removeFile = useCallback((originalName: string) => {
    const request = deleteFile(originalName);
    toast.promise(request, {
      pending: {
        render() {
          return "Carregando...";
        },
      },
      success: {
        render() {
          return "Documento removido com sucesso!";
        },
      },
      error: {
        render({ data }: any) {
          return "Falha ao remover documento!";
        },
      },
    });
  }, []);

  const getBanks = useCallback(() => {
    const request = listBanks();
    toast.promise(request, {
      pending: {
        render() {
          return "Carregando...";
        },
        style: {
          display: "none",
        },
      },
      success: {
        render({ data }: any) {
          setBanks(data?.data);
          return "Bancos carregados com sucesso!";
        },

        style: {
          display: "none",
        },
      },
      error: {
        render({ data }: any) {
          return "Falha ao carregar bancos!";
        },

        style: {
          display: "none",
        },
      },
    });
  }, []);

  return (
    <ContextApi.Provider
      value={{
        isAuthenticated,
        loginRequest,
        user,
        logoutRequest,
        getAllProducts,
        addProductRequest,
        editProductRequest,
        deleteProductRequest,
        products,
        productFiltered,
        productsById,
        adress,
        getAdressByPostalCode,
        getAllStates,
        ufs,
        getCitiesByUf,
        cities,
        drawerOpen,
        setDrawerOpen,
        spheresResp,
        getSpheresByUser,
        getAllCareer,
        career,
        clearProductFiltered,
        dimensions,
        getAllProductImages,
        productImages,
        getAllFaq,
        startTransaction,
        allFaq,
        getAllTransactionsByUserId,
        transactions,
        getAllCommissionsByUserId,
        commissions,
        getShippingCost,
        profileEditAgent,
        editAgentProfile,
        setEditAgentProfile,
        getAllDocuments,
        documents,
        deleteDocument,
        clearDocumentFiltered,
        documentById,
        documentFiltered,
        addDocumentRequest,
        mainScreemDetails,
        editAvatar,
        getFiles,
        files,
        removeFile,
        sendDocumentsRequest,
        getBanks,
        banks,
        listAllTransactions,
        allTransactions,
        getAllUsers,
        users,
        shippingCostResponse,
      }}
    >
      {children}
    </ContextApi.Provider>
  );
};

export default ContextProvider;
