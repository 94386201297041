import React from "react";
import styles from "./styleUseTermsPage.module.scss";

export function UseTermsPage(): JSX.Element {
  return (
    <div className={styles.container}>
      <h1>Termos de Uso do Plano NPAC</h1>
      <p>
        <strong>Última atualização:</strong> 04 de fevereiro de 2025
      </p>

      <p>
        Bem-vindo ao Plano NPAC. Ao acessar ou utilizar nossos serviços, você
        concorda em cumprir e estar vinculado a estes Termos de Uso. Se não
        concordar com qualquer parte destes termos, você não deve utilizar
        nossos serviços.
      </p>

      <h2>1. Definições</h2>
      <ul>
        <li>
          <strong>Conta:</strong> Registro único criado para acessar o Serviço.
        </li>
        <li>
          <strong>Afiliada:</strong> Entidade que controla, é controlada ou está
          sob controle comum com outra parte.
        </li>
        <li>
          <strong>Aplicativo:</strong> Refere-se ao Plano NPAC.
        </li>
        <li>
          <strong>Empresa:</strong> Referida como "a Empresa", "Nós", "Nos" ou
          "Nosso", refere-se ao Plano NPAC.
        </li>
        <li>
          <strong>Serviço:</strong> O aplicativo Plano NPAC.
        </li>
        <li>
          <strong>Usuário:</strong> Indivíduo ou entidade legal que acessa ou
          utiliza o Serviço.
        </li>
      </ul>

      <h2>2. Uso do Serviço</h2>
      <p>
        O Plano NPAC fornece uma plataforma para gerenciamento e utilização dos
        serviços oferecidos.
      </p>
      <p>
        Você é responsável por garantir a segurança de sua conta e por todas as
        atividades realizadas nela.
      </p>
      <p>
        É proibido o uso do Serviço para qualquer propósito ilícito ou não
        autorizado.
      </p>

      <h2>3. Coleta e Uso de Dados</h2>
      <p>
        Podemos coletar e processar informações conforme descrito em nossa
        Política de Privacidade.
      </p>

      <h2>4. Direitos e Responsabilidades</h2>
      <p>
        O Plano NPAC se reserva o direito de modificar, suspender ou encerrar
        qualquer parte do Serviço a qualquer momento, sem aviso prévio.
      </p>
      <p>
        Você concorda em utilizar o Serviço de forma legal e em conformidade com
        todas as leis e regulamentos aplicáveis.
      </p>

      <h2>5. Rescisão</h2>
      <p>
        Podemos suspender ou encerrar seu acesso ao Serviço sem aviso prévio
        caso você viole estes Termos de Uso.
      </p>

      <h2>6. Modificações nos Termos</h2>
      <p>
        Reservamo-nos o direito de modificar estes Termos de Uso a qualquer
        momento. Avisaremos sobre mudanças significativas por meio do Serviço ou
        por e-mail.
      </p>

      <h2>7. Contato</h2>
      <p>
        Se você tiver alguma dúvida sobre estes Termos de Uso, entre em contato
        conosco pelo e-mail:{" "}
        <a href="mailto:planonpacoficial@gmail.com">
          planonpacoficial@gmail.com
        </a>
        .
      </p>
    </div>
  );
}
