import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  Badge,
  Drawer as AntdDrawer,
  Button,
  Dropdown,
  Modal,
  Result,
  MenuProps,
} from "antd";
import { ContextApi } from "../../../../contexts";
import styles from "./styleDrawer.module.scss";
import { Divider } from "../../../divider";
import AvatarDefaut from "../../../../assets/images/avatar.jpg";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  Bell,
  Briefcase,
  File,
  Home,
  Info,
  Layers,
  LayoutDashboard,
  Lock,
  LogOut,
  LucideIcon,
  Settings,
  ShoppingCart,
  UserPlus,
  Wallet,
} from "lucide-react";
import { ROLES } from "../../../../constants/roles";
import CopyToClipboard from "react-copy-to-clipboard";
import { toast } from "react-toastify";
import {
  notifications,
  readNotifications,
} from "../../../../services/requests/notification";

interface Path {
  name: string;
  path: string;
  icon: LucideIcon;
  active?: boolean;
}

export default function Drawer() {
  const { drawerOpen, setDrawerOpen, user, logoutRequest, getFiles, files } =
    useContext(ContextApi);
  const location = useLocation();
  const navigate = useNavigate();

  const link = btoa(user?._id as string);
  const url = window.location.href.split("/");
  const redirectUrlInavtion = url[0] + "//" + url[2] + "/invitation/" + link;
  const iconSize = 14;

  const [notification, setNotification] = useState<any>([]);
  const [copied, setCopied] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const navItems: Path[] = useMemo(() => {
    const paths = [
      {
        name: "Visão Geral",
        path: "/",
        icon: Home,
      },
      {
        name: "Minha Loja",
        path: "/products",
        icon: ShoppingCart,
      },
      {
        name: "Estrutura",
        path: "/structure",
        icon: Layers,
      },
      {
        name: "Carreira",
        path: "/career",
        icon: Briefcase,
      },
      {
        name: "Financeiro",
        path: "/financial",
        icon: Wallet,
      },
      {
        name: "Documentos",
        path: "/documents",
        icon: File,
      },
      {
        name: "Ajuda",
        path: "/help",
        icon: Info,
      },
    ];
    const fullPaths =
      user?.role !== ROLES.user
        ? [
            {
              name: "Acesso Admin",
              path: "/admin",
              icon: LayoutDashboard,
            },
            {
              name: "Acesso Master",
              path: "/master",
              icon: Lock,
            },
            ...paths,
          ]
        : paths;
    return fullPaths.map((path) => {
      return { ...path, active: location.pathname === path.path };
    });
  }, [location, user]);
  const _fetch = async () => {
    await notifications(user!._id)
      .then((response) => {
        setNotification(response?.data);
      })
      .catch(console.log);
  };

  const handleNavigate = useCallback(
    (path: string) => {
      if (path === location.pathname) {
        return;
      }
      navigate(path);
      setDrawerOpen(!drawerOpen);
    },
    [drawerOpen, location, navigate, setDrawerOpen]
  );

  useEffect(() => {
    if (user) {
      getFiles("avatar", user?._id ?? "");
    }
  }, [user]);

  const readNotification = async (id: string) => {
    await readNotifications(id)
      .then(() => {
        _fetch();
      })
      .catch(console.log);
  };

  const avatar = files.find((avatar) => avatar.fieldName === "avatar") ?? null;
  const items: MenuProps["items"] = notification.map((notification: any) => {
    return {
      key: notification?._id,
      label: (
        <div
          className={styles.notification}
          onMouseLeave={() => readNotification(notification?._id)}
        >
          <span className={styles.textNotification}>
            {notification?.message ? notification?.message : "-"}
          </span>
          <ShoppingCart className={styles.icon} />
        </div>
      ),
    };
  });

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(redirectUrlInavtion);
      setCopied(true);
      setTimeout(() => {
        setCopied(false);
      }, 2000); // Reseta o estado após 2 segundos
      alert("Link copiado");
    } catch (err) {
      console.error("Failed to copy: ", err);
    }
  };

  return (
    <aside>
      <AntdDrawer
        width={window.innerWidth * 0.75}
        placement="left"
        onClose={() => setDrawerOpen(!drawerOpen)}
        open={drawerOpen}
      >
        <div className={styles.container}>
          <div className={styles.profileContainer}>
            <div className={styles.profileContainerBg}>
              <img
                alt="perfil"
                className={styles.profile}
                src={avatar?.path ?? AvatarDefaut}
              ></img>
            </div>
          </div>
          <div className={styles.userData}>
            <div className={styles.userName}>{user?.name}</div>
            <div className={styles.userId}>#{user?.userID}</div>
            <div className={styles.userStatus}>{user?.activeStatus}</div>
          </div>
          <div className={styles.rowButtons}>
            <Link
              to={"https://leonardomarcondes.com.br/destrava11sp/"}
              target="_blank"
              className={styles.orangeButton}
            >
              Destrava
            </Link>
            <Link
              to={
                "https://escola.npac.com.br/auth/login?redirect=/office/minha_cademi/aparencia"
              }
              target="_blank"
              className={styles.orangeButton}
            >
              Escola NPAC
            </Link>
          </div>
          <div className={styles.rowButtons}>
            <Wallet
              size={iconSize}
              className={styles.icon}
              onClick={() => showModal()}
            />
            <div className={styles.divider} onClick={handleCopy} />

            <UserPlus
              size={iconSize}
              className={styles.icon}
              color={copied ? "#00ff00" : "#8B8B8B"}
            />

            <div className={styles.divider} />

            <Badge count={items?.length} className={styles.icon}>
              <Dropdown menu={{ items }} placement="bottom" arrow>
                <Bell size={iconSize} />
              </Dropdown>
            </Badge>
            <div className={styles.divider} />
            <Settings
              size={iconSize}
              onClick={() => handleNavigate("/agent-profile")}
              className={styles.icon}
            />
            <div className={styles.divider} />
            <LogOut
              size={iconSize}
              onClick={logoutRequest}
              className={styles.icon}
            />
          </div>
          <Divider />
          <div className={styles.navList}>
            {navItems.map(({ icon: Icon, name, path, active }) => {
              return (
                <div
                  key={path}
                  onClick={() => handleNavigate(path)}
                  className={active ? styles.activeItem : styles.navItem}
                >
                  <Icon size={18} />
                  {name}
                </div>
              );
            })}
          </div>
        </div>
      </AntdDrawer>
      <Modal
        title="Carteira"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Result
          status="403"
          title="Dados da sua carteira não encontrados."
          subTitle="Desculpe não foi possível encontrar as informações da sua carteira."
          extra={
            <Button type="primary" onClick={() => handleCancel()}>
              Fechar
            </Button>
          }
        />
      </Modal>
    </aside>
  );
}
