import React from "react";
import styles from "./stylePrivacyPage.module.scss";

export function PrivacyPage(): JSX.Element {
  return (
    <div className={styles.container}>
      <h1>Política de Privacidade do Plano NPAC</h1>
      <p>
        <strong>Última atualização:</strong> 04 de fevereiro de 2025
      </p>

      <p>
        Esta Política de Privacidade descreve nossas políticas e procedimentos
        sobre a coleta, uso e divulgação de suas informações ao usar o Serviço e
        informa sobre seus direitos de privacidade e como a lei o protege.
      </p>
      <p>
        Utilizamos seus dados pessoais para fornecer e melhorar o Serviço. Ao
        usar o Serviço, você concorda com a coleta e uso de informações de
        acordo com esta Política de Privacidade.
      </p>

      <h2>Interpretação e Definições</h2>
      <h3>Interpretação</h3>
      <p>
        As palavras cuja letra inicial está em maiúsculo têm significados
        definidos sob as seguintes condições. As definições a seguir terão o
        mesmo significado, independentemente de estarem no singular ou no
        plural.
      </p>

      <h3>Definições</h3>
      <ul>
        <li>
          <strong>Conta:</strong> uma conta única criada para que você acesse
          nosso Serviço ou partes dele.
        </li>
        <li>
          <strong>Afiliada:</strong> uma entidade que controla, é controlada ou
          está sob controle comum com uma parte.
        </li>
        <li>
          <strong>Aplicativo:</strong> refere-se ao Plano NPAC.
        </li>
        <li>
          <strong>Empresa:</strong> referida como "a Empresa", "Nós", "Nos" ou
          "Nosso" neste Acordo, refere-se ao Plano NPAC.
        </li>
        <li>
          <strong>País:</strong> refere-se ao Brasil.
        </li>
        <li>
          <strong>Dispositivo:</strong> qualquer dispositivo que possa acessar o
          Serviço, como um computador, celular ou tablet digital.
        </li>
        <li>
          <strong>Dados Pessoais:</strong> qualquer informação relacionada a um
          indivíduo identificado ou identificável.
        </li>
        <li>
          <strong>Serviço:</strong> refere-se ao Aplicativo.
        </li>
        <li>
          <strong>Provedor de Serviço:</strong> qualquer pessoa física ou
          jurídica que processa os dados em nome da Empresa.
        </li>
        <li>
          <strong>Dados de Uso:</strong> dados coletados automaticamente,
          gerados pelo uso do Serviço ou pela infraestrutura do Serviço.
        </li>
      </ul>

      <h2>Coleta e Uso de Seus Dados Pessoais</h2>
      <h3>Tipos de Dados Coletados</h3>
      <p>
        Podemos solicitar que você nos forneça determinadas informações,
        incluindo:
      </p>
      <ul>
        <li>Endereço de e-mail</li>
        <li>Nome e sobrenome</li>
        <li>Número de telefone</li>
        <li>Endereço completo</li>
        <li>Dados de Uso</li>
      </ul>

      <h2>Segurança dos Seus Dados</h2>
      <p>
        A segurança dos seus Dados Pessoais é importante para nós, mas lembre-se
        de que nenhum método de transmissão pela Internet ou de armazenamento
        eletrônico é 100% seguro.
      </p>

      <h2>Privacidade das Crianças</h2>
      <p>
        Nosso Serviço não é direcionado a menores de 13 anos. Não coletamos
        intencionalmente informações de identificação pessoal de menores de 13
        anos.
      </p>

      <h2>Alterações a Esta Política de Privacidade</h2>
      <p>
        Podemos atualizar nossa Política de Privacidade periodicamente. A nova
        política será publicada nesta página e você será notificado sobre
        quaisquer mudanças significativas.
      </p>

      <h2>Contato</h2>
      <p>
        Se tiver alguma dúvida sobre esta Política de Privacidade, entre em
        contato conosco pelo e-mail:{" "}
        <a href="mailto:planonpacoficial@gmail.com">
          planonpacoficial@gmail.com
        </a>
        .
      </p>
    </div>
  );
}
