import React, { useState, useEffect } from "react";
import { ColumnsType } from "antd/es/table";
import { Table, message } from "antd";
import { adressByCEP } from "../../../services/requests/postalService";

interface AddressProps {
  currentTransaction: any;
}

const Address: React.FC<AddressProps> = ({ currentTransaction }) => {
  const [addressData, setAddressData] = useState<any>({});
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    const fetchAddressData = async () => {
      if (currentTransaction?.customer?.address?.zip_code) {
        setIsLoading(true);
        try {
          const res = await adressByCEP(
            currentTransaction.customer.address.zip_code
          );
          setAddressData(res.data);
        } catch (error) {
          message.error("CEP inválido ou erro ao buscar endereço.");
        } finally {
          setIsLoading(false);
        }
      }
    };

    fetchAddressData();
  }, [currentTransaction]);

  const columns: ColumnsType<any> = [
    {
      title: "Nome",
      key: "name",
      render: (data) => <span>{data?.customer?.name}</span>,
    },
    {
      title: "E-mail",
      key: "email",
      render: (data) => <span>{data?.customer?.email}</span>,
    },
    {
      title: "CPF",
      key: "document",
      render: (data) => <span>{data?.customer?.document}</span>,
    },
    {
      title: "Endereço",
      key: "address",
      render: (data) => (
        <span>
          {data?.customer?.address?.line_1 || ""}{" "}
          {data?.customer?.address?.line_2 || ""}
        </span>
      ),
    },
    {
      title: "CEP",
      key: "zip_code",
      render: (data) => <span>{data?.customer?.address?.zip_code}</span>,
    },
    {
      title: "Estado",
      key: "state",
      render: (data) => <span>{addressData?.estado}</span>,
    },
    {
      title: "Cidade",
      key: "city",
      render: (data) => <span>{addressData?.localidade}</span>,
    },
  ];

  return (
    <div>
      {currentTransaction && (
        <Table
          columns={columns}
          dataSource={[currentTransaction]}
          loading={isLoading}
          rowKey={(record) => record?.id || "row"}
        />
      )}
    </div>
  );
};

export default Address;
